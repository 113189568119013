<template>
  <div class="d-flex flex-column justify-items-center">
    <img class="mb-4 mx-auto" src="../../assets/logo.svg" alt="Toast Lettings" width="150">
    <b-card>
      <b-form @submit.prevent="onSubmit">
        <b-form-group label="Password" label-for="password" :invalid-feedback="getValidationMessage('password')" :state="!getValidationMessage('password')">
          <b-form-input :disabled="loading" id="password" required type="password" v-model="password"></b-form-input>
        </b-form-group>
        <b-form-group label="Confirm Password" label-for="password_confirmation" :invalid-feedback="getValidationMessage('password_confirmation')" :state="!getValidationMessage('password_confirmation')">
          <b-form-input :disabled="loading" id="password_confirmation" required type="password" v-model="password_confirmation"></b-form-input>
        </b-form-group>
        <div class="d-flex flex-row w-100">
          <div class="flex-fill"></div>
          <b-button class="ml-2" variant="link" @click="$router.push('/auth/login')">Log In?</b-button>
          <b-button class="ml-2" type="submit" variant="primary"><b-spinner class="mx-3" small variant="light" v-if="loading" /><span v-if="!loading">Change Password</span></b-button>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import handleErrors from '../../mixins/handleErrors'
import { mapActions, mapState } from 'vuex'

export default {
  mixins: [handleErrors],
  data() {
    return {
      password: '',
      password_confirmation: ''
    };
  },
  computed: {
    ...mapState('auth', ['loading', 'user']),
  },
  methods: {
    ...mapActions('auth', ['check', 'login', 'reset']),
    onSubmit() {
      const token = this.$route.params.token;
      const email = this.$route.query.email;

      this.reset({
        token,
        email,
        password: this.password,
        password_confirmation: this.password_confirmation
      });

      this.$router.push('/auth/login');
    }
  }
}
</script>

<style>

</style>
